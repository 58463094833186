

.auth-text {
  width: 80%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  /* margin-bottom: 667px; */ /* height of iPhone 6 */
}

.text-center {
  text-align: center;
}

@media (min-width: 450px) {
  .auth-text {
    /* margin-bottom: unset; */
  }
}
