

.form-container {
  display: inline;
}

.auth-container {
  padding-bottom: 500px;
}

.spacer-bottom {
  /* height: unset; */
}

@media (min-width: 100px){
  .spacer-bottom {
    /* height: 600px; */
  }
}
