@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');

@font-face {
    font-family: dlmbold;
    src: url(https://rcm-assets.s3.amazonaws.com/fonts/dlm-webfont.eot),
    url(https://rcm-assets.s3.amazonaws.com/fonts/dlm-webfont.svg),
    url(https://rcm-assets.s3.amazonaws.com/fonts/dlm-webfont.ttf),
    url(https://rcm-assets.s3.amazonaws.com/fonts/dlm-webfont.woff),
    url(https://rcm-assets.s3.amazonaws.com/fonts/dlm-webfont.woff2);
    font-weight: 400;
    font-style: normal;
}

.hero.is-primary {
  background-color: #fff;
  color: #000;
}
.button.is-primary, .button.is-primary:hover, .button.is-success, .tag:not(body).is-primary {
  background-color: #241773;
}
.button.is-primary:hover, .button.is-success:hover {
  background-color: #574cc6;
}

.profile-section-block {
  padding: 1em;
}

.is-danger {
  color: blue!important;
}

body {
  font-family: 'Lato', sans-serif;
  height: 100%;
  background-color: #F2F3F7;
}

html {
    height: 100%;
    background-color: #F2F3F7;
}

@media (min-width: 591px) {
  body {
      height: 100%;
     font-size: 100%;
  }
}
