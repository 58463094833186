.transpositions {
  padding: 1em;
  display: flex;
  flex-direction: row;
}

.transposition {
  display: flex;
  flex-direction: column;
  padding: 1em;
  text-align: center;
  margin-left: 1em;
  margin-right: 1em;
}

.transpositions-container {
  padding: 1em;
  /* width: 360px; */
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.select-button {
  height:4em;
  width: 6em;
}

.single-note {
  /* vertical-align: middle; */
  vertical-align: sub;
}



.compare-area {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 56px;
  margin-top: 40px;
  display: flex;
  flex-direction: row;

  background: #fbfbfd;
  border-radius: 20px;
  border: 2px solid #ffffff;
  padding: 1rem 1.5rem 4.5rem 1rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .15);
}
.note {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 38px;
  line-height: 46px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.5px;

  /* primary */
  color: #251E50;
}

.instrument {
  font-family: Lato;
  font-style: normal;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.5px;

  color: #7C96FF;

  margin-top: .5rem;
  font-weight: 700;
  font-size: 16px;
}

.instrument .dlmbold {
  font-weight: 700;
}

.compare-area-transposition-text {
  width: 84px;
  letter-spacing: 0.5px;
  /* text-transform: uppercase; */

  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 48px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.5px;

  /* primary */
  color: #251E50;
  opacity: 0.8;
}

.back-button {
  background: #FFFFFF;
  margin: 15px;
  height: 58px;
  /* secondary */
  border: 2px solid #7C96FF;
  box-sizing: border-box;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 54px;

  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;

  padding-left: 10px;
  padding-right: 20px;

  /* secondary */
  color: #7C96FF;
}

.back-button:hover {
  background: #7C96FF;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 48px;
}

.btn-instrument {
  width:14em;
}

.btn-transposition {
  width:15em;
  /* font-size: 1.1em; */
}

.button-icon {
  margin-right: 10px;
}

@media (min-width: 850px) {
  .transpositions-container {
    width: 828px;
  }
}
