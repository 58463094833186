.profile-page-container {
  width: 100%;
  display: flex;
}

.profile-page-left {
  width: 70%;
  margin-left: 3em;
}

.profile-page-right {
  width: 30%;
}

.video-list {
  display: block;
  width: 100%;
  height: 100%;
}
.profile-video-list-title {
  font-size: 2em;
  font-weight: bold;
  margin-left: 1em;
}
