

.ad {
    width: 100%;
    margin-top: 4rem;
    color: #fbfbfd;
    padding: 1rem;

  }

  .ad.animated {
    animation-delay: 0.5s;
  }

  .ad .copy{

    width: 100%;
    max-width: 600px;
    height: 250px;
    background-color: #636363;
    background: url("https://rcmusic-kentico-cdn.s3.amazonaws.com/rcm/media/ggs/layout/rebanks-group-photo-2019-20-photo-credit-bo-huang.png");
    background-size: cover;

    margin: auto;
    padding: 2rem;
    font-weight: 600;
    letter-spacing: .3px;
    border-radius: 4px;
  }


  .ad h2{
    font-weight: 600;
    margin-top: 0;
    padding-top: 0;
  }


  .ad .ad-button {
    margin-top: 1.5rem;
  }

  .ad .ad-button {
    text-align: left;
}

  .ad .ad-button a {
    background-color: #fff;
    padding: .5rem 1rem;
    border-radius: 100px;
    margin-top: 3rem;
    color: #636363;
  }

  .text {
    font-size: .9em;
    text-align: left;
  }

  H2 {
    font-size: 1.4em;
  }

  .text h2 {
 font-size: 2rem;
 line-height: .9;
}

.text p {
 font-size: 1.2rem;
 max-width: 300px;
}

.ad .copy {
 background: url(https://digitallearnings3bucket.s3-us-west-2.amazonaws.com/Projects/Toolbox/ad_bkg_long.png);
 background-size: cover;
 background-position: right;
}

  @media screen and (max-width: 560px) {
 .ad .copy {
 background: url(https://digitallearnings3bucket.s3-us-west-2.amazonaws.com/Projects/Toolbox/ad_bkg_short.png);
 background-size: cover;
 background-position: right;
 }
}

@media screen and (max-width: 490px) {
 .text h2 {
 max-width: 300px;
 font-size: 1.7rem;
 }
 .text p {
 max-width: 250px;
 font-size: 1rem;
 }
}

@media screen and (max-width: 420px) {
 .text h2 {
 max-width: 200px;
 }
 .text p {
 max-width: 210px;
 font-size: .9rem;
 }
}

@media screen and (max-width: 390px) {
 .ad .copy {
 padding-left: 15px;
 background: url(https://digitallearnings3bucket.s3-us-west-2.amazonaws.com/Projects/Toolbox/ad_bkg_mini.png);
 background-size: cover;
 background-position: right;
 }
 .text h2 {
 max-width: 180px;
 }
 .text p {
 max-width: 210px;
 }
}
