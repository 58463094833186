.video-react .video-react-poster {
  background-color: transparent;
}

.video-react {
  background-color: transparent;
}

.masterclass-page-container {
  width: 100%;
  display: flex;
  height: 1000px;
}

.masterclass-page-left {
  width: 20%;
  text-align: -webkit-center;
}

.masterclass-page-right {
  width: 80%;
  margin-right: 1em;
}

.masterclass-instruments-list {
  width: 90%;
  background-color: #241773;
  height:auto;
  color: white;
  padding-bottom: 2em;
}

.masterclass-instrument {
  cursor: pointer;
  font-size: 1.5em;
  padding: 0.2em;
}

.masterclass-list-container {
  width: 100%;
  display: flex;
}

.masterclass-item {
  width: 100%;
  display: flex;
  background-color: #241773;
  padding:1em;
  margin-left: 1em;
  margin-bottom: 1em;
}

.masterclass-pic {
  color: white;
  background-color: black;
  width: 10em;
  height: 10em;

}

.masterclass-overview {
  width: 90%;
  background-color:white;
  color: black;
  margin-right: 1em;
  margin-left: 1em;
  padding-left: 0.2em;
  padding-top: 0.4em;
}

.masterclass-overview .title {
  font-weight: bold;
  font-size: 1em;
  margin-bottom: 0.2em;
}

.masterclass-overview .description {
  font-size: 1em;
  padding-top: 0.2em;
}

.video-area {
  width: 100%;
  display: flex;
}

.video-player {
  /* padding: 3em; */
  width: 100%;
}

.video-title {
  font-weight: bold;
  text-align: left;
  padding: 1em;
  font-size: 2em;
}

.video-description {
  text-align: left;
  padding-left: 2em;
  padding-bottom: 2em;
  font-size: 1em;
}

.masterclass-list {
  display: block;
  width: 100%;
  height: 100%;
}

.video-list-cell {
  width: 90%;
  height: auto;
  margin-bottom: 1em;
  margin-left: 1em;
  margin-right: 1em;
  padding-right: 0.5em;
  cursor: pointer;
}

.video-thumb {
  display: table;
  margin: 0 auto;
  width: 100%;
}

.video-cell-title {
  font-size: 1.4em;
}

.video-cell-description {
  font-size: 1em;
}

.video-options {
  padding-left: 1em;
  padding-bottom: 1em;
}
