.pitch-title-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* width: 360px; */
  height: 65px;
  margin-top: 40px;
}

.compare-area-left-text {
  width: 84px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 48px;
  color: #251e50;
  opacity:  .8;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.5px;
  /* text-transform: uppercase; */
}

.compare-area-left-text #concert, .compare-area-left-text #F {
  margin-top: .5rem;    
}

#concert {
  font-size: 18px;
}

.pitch-button {
  width: 100px;
  height: 60px;
}

.pitch-buttons-container {
  /* width: 392px; */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 850px) {
  .pitch-buttons-container {
    width: 850px;
  }
}

@media (min-width: 380px) {
  .pitch-title-container {
    width: 380px;
  }
}
