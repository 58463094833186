.instruments-title-container.animated {
  /* animation-delay: 1s; */
}

.instruments-buttons.animated, .pitch-buttons-container.animated {
  animation-delay: 0.28s;
  animation-duration: .5s;
}

.compare-area.animated {
  animation-delay: 0.35s;
  animation-duration: .28s;
}

.underline-highlight.animated {
  animation-duration: .8s;
  animation-iteration-count: infinite;
}

.middle-arrow-transposition.animated {
  animation-duration: .8s;
  animation-iteration-count: infinite;
}

.navbuttons.animated {
  animation-delay: .5s;
  animation-duration: .7s;
}

.transpositions-container.animated {
  animation-delay: 0.3s;
  animation-duration: .7s;
}

Button.animated {
 animation-duration: 0s;
}

Button.animated:hover {
 animation-duration: 1s;
 animation-iteration-count: infinite;
}
