.video-react .video-react-poster {
  background-color: transparent;
}

.video-react {
  background-color: transparent;
}


.video-page-container {
  width: 100%;
  display: flex;
}

.video-page-left {
  width: 80%;
}

.video-page-right {
  width: 20%;
}

.video-area {
  width: 100%;
  display: flex;
}

.video-player {
  /* padding: 3em; */
  width: 100%;
}

.video-title {
  font-weight: bold;
  text-align: left;
  padding: 1em;
  font-size: 2em;
}

.video-description {
  text-align: left;
  padding-left: 2em;
  padding-bottom: 2em;
  font-size: 1em;
}

.video-list {
  display: block;
  width: 100%;
  height: 100%;
}

.video-list-cell {
  width: 90%;
  height: auto;
  margin-bottom: 1em;
  margin-left: 1em;
  margin-right: 1em;
  padding-right: 0.5em;
  cursor: pointer;
}

.video-thumb {
  display: table;
  margin: 0 auto;
  width: 100%;
}

.video-cell-title {
  font-size: 1.4em;
}

.video-cell-description {
  font-size: 1em;

}

.video-options {
  padding-left: 1em;
  padding-bottom: 1em;
}
