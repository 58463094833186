.header-container {
  top: 40px;
  left: 60px;
}

.landing-brand {
  width: 157px;
  height: 76px;
  margin-top: 40px;
  margin-left: 50px;
}

.landing-icon {
  width: 100%;
  height: 49.31px;
  display: block;
  left: auto;
  right: auto;
}

.landing-main {
  display: block;
  margin-top: 100px;
  left: auto;
  right: auto;
  text-align: center;
}

.landing-rcm-text {
  margin-top: 32px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.5px;

  /* primary */
  color: #251E50;
}

.landing-title {
  margin-top: 10px;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  /* primary */
  color: #251E50;
}

.landing-buttons {
  margin-top: 60px;
}

.landing-button-alt {
  width: 146px;
  height: 58px;
  border: 2px solid #241773;
  box-sizing: border-box;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 54px;
  background-color: unset;

  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.5px;

  color: #241773;

  margin-top: 10px;
}

.landing-button {
  width: 146px;
  height: 58px;
  background: #241773;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 54px;

  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.5px;

  color: white;

  margin-top: 10px;
}

.landing-button:hover, .landing-button-alt:hover {
  background-color: #7C96FF;
  border: 2px solid #FFFFFF;
}
