
.title {
  padding: 1em;
  width: 100%;
  text-align: center;
}

.instruments-buttons {
  padding: 1em;
  width: 100%;
  text-align: center;
  /* margin-bottom: 10em; */
  margin-top: 2em;
}

.compare-area {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  height: 56px;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.compare-area-left {
  width: 84px;
  height: inherit;
}

.compare-area-right {
  width: 84px;
  height: inherit;
}

.compare-area-center {
  width: 48px;
  height: 48px;
}

.instruments-title-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  height: 65px;
  margin-top: 40px;
}

.page-title-small {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  margin-left: 20px;
}

.page-title-large {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 43px;
  margin-left: 20px;
}

.navbuttons {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 5em;
}

.button {

}
