@import url('https://fonts.googleapis.com/css?family=Lato:300,400');

.hero.is-primary {
  height: window.innerHeight;
  margin: 0;
}

.hero-body {

}

.bg-image {
    position: relative;
}
.bg-image img {
    display: block;
    width: 100%;
    max-width: 2048px; /* corresponds to max height of 450px */
    margin: 0 auto;
}
.bg-image .hero-main-text-box {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    width:60em;
    height:25em;

    top: 1em;
    right: 1em;
    color: black;
    padding: 1em;
}

h1 {
  font-family: "Lato", sans-serif;
  font-style: normal;
  color: inherit;
  text-rendering: optimizeLegibility;
}

.bg-image h1, h2, h3 {
    font-weight: 300;
    padding-top: 1em;
}

.bg-image p {
    padding-top: 1em;
}

.bg-image .box1 {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    width:19em;
    height:25em;

    bottom: 5em;
    right: 1em;
    color: black;
    padding: 1em;
}

.bg-image .box2 {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    width:19em;
    height:25em;

    bottom: 5em;
    right: 21em;
    color: black;
    padding: 1em;
}

.bg-image .box3 {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    width:19em;
    height:25em;

    bottom: 5em;
    right: 41em;
    color: black;
    padding: 1em;
}
