
.form-container {
  display: block;
  position: relative;
  margin-top: 60px;
  /* margin-bottom: 60px; */
}

.form-col-left {
  display: block;
  position: relative;
  width: 420px;
}

.form-col-right {
  display: block;
  position: relative;
  width: 420px;
}

.field-area {
  width: 420px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.form-field {
  width: 420px;
}

.field-title {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: 0.5px;

  /* primary */
  color: #251E50;

  margin-bottom: 5px;
  width: 400px;
}

.form-input {
  width: 70%;
  height: 50px;
  padding-left: calc(.625em - 1px);
  background: #FFFFFF;
  border: 1px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 4px;
}

.input-small {
  width: 273px;
  margin-left: 0;
  margin-right: 0;
}

.field-left {
  margin-right:auto;
  padding-left: 0;
}

.field-right {
  margin-left:unset;
  padding-left: 0;
  padding-right: 0;
}

.small-container {
  width: 420px;
  margin-right: auto;
}

.register-buttons-area {
  margin-top: 30px;
  /* margin-bottom: 300px; */
}
.register-button {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.warning {
  width: 300px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5px;

  /* primary */
  color: #251E50;
  opacity: 0.8;

  margin-top: 8px;
}

.link {

  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 58px;
  /* identical to box height */
  text-align: right;
  letter-spacing: 0.5px;
  text-decoration-line: underline;

  color: #241773;
  margin-top: 60px;
  height: 58px;
}

.button-left {
  margin-left: auto;
}

.button-right {
   margin-right: auto;
}

.text-right {
  text-align: center!important;
  padding-left: inherit;
  padding-right: 0;
}


@media (min-width: 100px){
  body {
    height: unset;
  }

  .input-small {
    width: 294px;
  }
}

@media (min-width: 375px){
  .form-input {
    width: 80%;
  }

  .input-small {
    width: 336px;
  }

  .warning {
    width:340px;
  }

}

@media (min-width: 450px){
/*
  body {
    height: 100%;
  }
*/
  .form-input {
    width: 420px;
  }

  .warning {
    width:420px;
  }

  .input-small {
    width: 200px;
  }

  .field-right {
    margin-left:auto;
    /* padding-left: 15px; */

  }
  .small-container {
    margin-left: -15px;
  }

  .button-left {
    margin-left: 0;
  }

  .button-right {
    margin-right: 0;
  }

  .text-right {
    text-align: right!important;
  }
}

@media (min-width: 510px){
  .small-container {
    margin-left: auto;
  }
}

@media (min-width: 830px){

}
