.tool-container {
  display: flex;
  flex-direction: row;
  margin: 0;
  /* height: -webkit-fill-available; */
}

.tool-title {
  background: #251E50;
  border-radius: 11px;
  color: white;
  line-height: 30px;
  width: 260px;
  height: 30px;
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}


.converter-button {
  background: #FFFFFF;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  margin: 10px;

  font-family: Lato;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.5px;

  /* primary */
  color: #251E50;

}

.converter-button:hover {
  background: #7C96FF;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
}

.instruments-pitch-button {
  width: 120px;
  height: 60px;
}

.pitch-converter-button {
  margin: 1em;
  border-radius: 25px;
}

.converter-underline {
  width: 56px;
  height: 8px;
  top: 48px;
  position: relative;
  border-radius: 16px;
  display: block;
  margin-left: auto;
  margin-right: auto;

  background: #251E50;
  opacity: 0.15;
  border-radius: 16px;
}

.underline-highlight {
  opacity: 1;
  background: #7C96FF;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
}

.middle-arrow-transposition {
  margin-top: 0.5em;
}

@media screen and (min-width: 415px){
  .username {
    display: none;
  }
  .tool-container {
    flex-direction: row;
  }
  .tool-container > * {
    flex: 1;
  }
}

@media (min-width: 450px) {
  .tool-container {
    /* height: -webkit-fill-available; */
  }
}

@media (min-width: 576px) {
  .container {
    max-width: inherit;
  }
  .username {
    display:none;
  }
}

@media (min-width: 625px) {
  .container {
    max-width: inherit;
  }
  .username {
    display:none;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: inherit;
  }
  .username {
    display:block;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: inherit;
  }
  .username {
    display:block;
  }
}

@media (min-width: 1200px){
  .container {
    max-width: inherit;
  }
  .username {
    display:block;
  }
}
