

.button {
  margin-right: 1em;
}

.navbar {
  background-color: inherit;
  color: #251E50;
}

.navbar-brand {
  width: 86.8px;
  height: 42px;
  display: table;
  margin: 0 auto;
  margin-top: 15px;
}

.navbar-menu {
  margin-top: 25px;
  background-color: inherit;
}


.navbar-profile {
  display: flex;
  flex-direction: col;
  float: right;
  margin-top: 25px;
}

.profile-popup {
  position: absolute;
  top: 60px;
  right: 15px;
  width: 136px;
  height: 42px;
  background: #FFFFFF;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 25px 0px 25px 25px;

  color: #251E50;
  opacity: 0.8;
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 42px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.5px;
}

.username {
  font-family: 'Lato', sans-serif;
  margin-right: 9px;
  display: none;
}
